import React from "react";
import { icons } from "../../constants/images/Icons";
import { images } from "../../constants/images/Images";
import { useNavigate } from "react-router-dom";
import { addToFavorites } from "../../../backend/add_favorite";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Renders a featured product card.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.product - The product data.
 * @param {Function} props.formatPrice - The function to format the price.
 * @param {Function} props.getIconComponent - The function to get the icon component.
 * @returns {JSX.Element} The rendered component.
 */
const FeaturedProductCard = ({ product, formatPrice, getIconComponent }) => {
  const navigate = useNavigate();

  const navigateToDetails = () => {
    navigate(`/product/${product.id}`);
    window.scrollTo(0, 0);
  };

  // i should probably move these to a separate file and import them but i'm lazy so i'll just copy them here for now :D
  // THE ABOVE COMMENT WAS GENERATED BY COPILOT (i love you co-pilot)
  // HANDLE FAVOURITE CLICK

  const token = localStorage.getItem("token");
  const handleFavouriteClick = async (event) => {
    event.stopPropagation(event);
    event.preventDefault();
    try {
      const data = await addToFavorites(token, product.id);
      if (data.message === "Added!") {
        toast.success("Product added to favorites!", {
          position: "top-center",
        });
      } else if (data.message === "Removed!") {
        toast.error("Product removed from favorites!", {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Error adding to favorites:", error);
      toast.error("Error: " + error.message, {
        position: "top-center",
      });
    }
  };

  // HANDLE SHARE CLICK
  const handleShareClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    navigator.clipboard
      .writeText(window.location.href + "product/" + product.id)
      .then(() => {
        toast.success("Product link copied to clipboard!", {
          position: "top-center",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy link: " + err.message, {
          position: "top-center",
        });
      });
  };

  // HANDLE VISIT WEBPAGE CLICK
  const handleVisitWebpageClick = (event) => {
    event.stopPropagation();
    window.open(product.url, "_blank");
  };
  console.log(product);
  return (
    <section
      className="bg-white flex rounded-lg mb-12"
      style={{
        boxShadow:
          "0 -2px 5px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.1)",
      }}
      onClick={navigateToDetails}
    >
      <div className="flex">
        <div className="flex items-center justify-center h-full">
          <img
            src={product.picture_url || images.placeholder}
            alt={product.name || "Placeholder"}
            className="max-w-72 max-h-72 object-contain rounded-lg p-4"
          />
        </div>
        <div className="w-2/3 flex flex-col justify-between p-6">
          <div>
            <h3 className="text-xl font-black">{product.name}</h3>
            <div className="flex items-center space-x-2">
              <icons.Tag style={{ width: "20px", height: "20px" }} />
              <p className="text-gray-500">
                {product.site?.category?.name || "No category"}
              </p>
            </div>
            <div className="flex items-start mt-6 space-x-4">
              <div className="mr-4">
                <p className="text-gray-500">Current price:</p>
                <p
                  className={`text-2xl text-bold ${
                    product.change_percentage > 0
                      ? "text-red-500"
                      : "text-green-500"
                  }`}
                >
                  {formatPrice(product.current_price)}
                </p>
                <span
                  className={`text-sm ${
                    product.change_percentage > 0
                      ? "text-red-500"
                      : "text-green-500"
                  }`}
                >
                  {product.change_percentage > 0 ? "+" : "-"}
                  {Math.abs(product.change_percentage)}%
                </span>
              </div>
              <div className="flex flex-col">
                <p className="text-gray-500">Current cheapest website:</p>
                <div className="flex items-center">
                  <div className="mr-2">
                    {getIconComponent(product.site?.site_name) &&
                      React.createElement(
                        getIconComponent(product.site.site_name),
                        {
                          style: { width: "20px", height: "20px" },
                        }
                      )}
                  </div>
                  <p className="text-gray-500">
                    {product.site?.site_name || "Unknown"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 flex items-center space-x-2 gap-4">
            <div
              className="flex flex-row items-center gap-1 cursor-pointer"
              onClick={handleFavouriteClick}
            >
              <icons.Heart style={{ width: "20px", height: "20px" }} />
              <a href="#" className="text-black">
                Favourite
              </a>
            </div>
            <div
              className="flex flex-row items-center gap-1 cursor-pointer"
              onClick={handleShareClick}
            >
              <icons.Link style={{ width: "20px", height: "20px" }} />
              <a href="#" className="text-black">
                Share
              </a>
            </div>
            <div
              className="flex flex-row items-center gap-1 cursor-pointer"
              onClick={handleVisitWebpageClick}
            >
              <icons.Plane style={{ width: "20px", height: "20px" }} />
              <a className="text-black">Visit webpage</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedProductCard;
